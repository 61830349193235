import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="w-[100vw] h-[100vh] flex justify-center items-center">
      <div>
        <h1 className="text-5xl sm:text-3xl text-[#5222D0] my-2 font-bold dark:text-transparent dark:bg-darkHeading dark:bg-clip-text">
          404: Not Found
        </h1>
        <p className="text-justify text-[#1F2626] dark:text-[#BDEBEA] my-2">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
